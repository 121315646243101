import { useState, useEffect } from 'react';
import { usePathname, useSearchParams } from 'next/navigation';

import { blacklistedSignupPaths, PROD_HOST } from '../constants';

export function isValidRedirectPath(currentPath: string) {
  const matches = blacklistedSignupPaths.filter((path) =>
    currentPath.match(path)
  );
  return matches.length === 0;
}

export function useSignup() {
  const pathname = usePathname();
  const searchParams = useSearchParams();
  const redirect = searchParams.get('redirect');
  const currentPath = searchParams.size
    ? `${pathname}?${searchParams}`
    : pathname;
  // We need to use the production host as the initial value for server side rendering as window.location.origin is not available
  const signupHost = `${PROD_HOST}/signup/`;
  const url = new URL(signupHost);
  const [signupUrl, setSignupUrl] = useState(signupHost);

  const redirectPath = (() => {
    if (redirect) {
      return redirect;
    }
    return currentPath !== '/' && isValidRedirectPath(currentPath)
      ? currentPath
      : '';
  })();

  if (redirectPath) {
    url.searchParams.append('redirect', redirectPath);
  }

  useEffect(() => {
    const { href } = url;

    if (!href || !PROD_HOST) {
      return;
    }
    // updating the url to contain the current domain name
    const isProd = window.location?.origin?.includes(PROD_HOST);
    setSignupUrl(
      isProd ? href : href.replace(PROD_HOST, window.location.origin)
    );
  }, [url.href]);

  return { url: signupUrl };
}
