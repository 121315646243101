export const APPLE_SDK_SCRIPT_SOURCE_URL =
  'https://appleid.cdn-apple.com/appleauth/static/jsapi/appleid/1/en_GB/appleid.auth.js';

export const APPLE_SCRIPT_LOAD_EVENT_NAME = 'appleScriptLoaded';

export const FIVE_MINUTE_EXPIRY = 300;
export const COOKIE_ACCOUNT_LINKING_TOKEN_OPTIONS = {
  path: '/',
  maxAge: FIVE_MINUTE_EXPIRY,
  secure: true,
};
export const COOKIE_ACCOUNT_LINKING_TOKEN_APPLE = 'pre_al_token_apple';
export const COOKIE_ACCOUNT_LINKING_DETAILS_APPLE = 'pre_al_details_apple';
export const COOKIE_ACCOUNT_LINKING_TOKEN_GOOGLE = 'pre_al_token_google';
export const COOKIE_ACCOUNT_LINKING_DETAILS_GOOGLE = 'pre_al_details_google';
export const COOKIE_ACCOUNT_LINKING_MFA = 'pre_al_mfa';
export const COOKIE_MFA_TOKEN = 'pre_mfa_token';
export const COOKIE_ACCOUNT_LINKING_SSO_EMAIL = 'SSOEmail';

export const ONE_HOUR_EXPIRY = 3600;
export const COOKIE_MFA_TOKEN_OPTS = {
  path: '/',
  maxAge: ONE_HOUR_EXPIRY,
  secure: true,
};

export const GENERIC_ERROR = 'An unknown error has occurred';

export const APPLE_REDIRECT_URI = {
  '/login/': '/login/',
  '/signup/': '/signup/',
  '/signup/email/': '/signup/',
  '/magic-link/': '/signup/',
};
