export const fallbackLinks = {
  de: {
    AppStore: 'https://itunes.apple.com/de/app/depop/id518684914?mt=8',
    PlayStore:
      'https://play.google.com/store/apps/details?id=com.depop&hl=de_DE',
  },
  en: {
    AppStore: 'https://itunes.apple.com/gb/app/depop/id518684914?mt=8',
    PlayStore:
      'https://play.google.com/store/apps/details?id=com.depop&hl=en_GB',
  },
  fr: {
    AppStore: 'https://itunes.apple.com/fr/app/depop/id518684914?mt=8',
    PlayStore:
      'https://play.google.com/store/apps/details?id=com.depop&hl=fr_FR',
  },
  it: {
    AppStore: 'https://itunes.apple.com/it/app/depop/id518684914?mt=8',
    PlayStore:
      'https://play.google.com/store/apps/details?id=com.depop&hl=it_IT',
  },
};
