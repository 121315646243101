import http from '@/modules/http';
import { AxiosCompatibleResponse } from '@/modules/http/types';
import {
  USER_BALANCE,
  USER_COUNTERS_ENDPOINT,
  USER_SETTINGS,
} from '@/constants/endpoints';
import {
  FetchUserBalanceResponse,
  UpdateUserSettingsRequest,
  UpdateUserSettingsResponse,
  UserCountersResponse,
  UserSettings,
} from '@/modules/user/types';

export function fetchUserCounters(
  userId: number
): Promise<AxiosCompatibleResponse<UserCountersResponse>> {
  return http.get(USER_COUNTERS_ENDPOINT.replace(':userId', String(userId)), {
    withAuth: true,
  });
}

export function fetchUserSettings(): Promise<
  AxiosCompatibleResponse<UserSettings>
> {
  return http.get(USER_SETTINGS, {
    withAuth: true,
  });
}

export function updateUserSettings(
  data: UpdateUserSettingsRequest
): Promise<AxiosCompatibleResponse<UpdateUserSettingsResponse>> {
  return http.patch(USER_SETTINGS, data, { withAuth: true });
}

export function getUserBalance(): Promise<AxiosCompatibleResponse<FetchUserBalanceResponse> | null> {
  return http.get(USER_BALANCE, { withAuth: true });
}
