import { RegisterOptions } from 'react-hook-form';

import { PHONE_CODES } from '../countries/constants';

import { UserSignupDetails } from './types';

export const EMAIL_SIGN_UP_FORM_SESSION_STORAGE_KEY =
  'email_signup_form_details';

export const SSO_SIGN_UP_CREDENTIALS_SESSION_STORAGE_KEY =
  'sso_signup_credentials';

export const EMAIL_SIGNUP_PHONE_VERIFICATION_FORM_SESSION_STORAGE_KEY =
  'email_signup_phone_form_details';

export const SIGNUP_ERROR_SESSION_STORAGE_KEY = 'signup_error_details';

export const EMAIL_SIGN_UP_DEFAULT_FORM_VALUES: UserSignupDetails = {
  firstName: '',
  lastName: '',
  email: '',
  username: '',
  password: '',
  marketingConsent: false,
  country: { value: '', label: '' },
};

export const SOCIAL_SIGN_UP_DEFAULT_FORM_VALUES: UserSignupDetails = {
  firstName: '',
  lastName: '',
  email: '',
  username: '',
  marketingConsent: false,
  country: { value: '', label: '' },
};

export const blacklistedSignupPaths = [
  /^\/login\//,
  /^\/signup\//,
  /^\/password-reset\//,
];
export const PROD_HOST = 'https://depop.com';

export const SIGNUP_FORM_ROUTES: Record<
  string,
  { path: string; step: number; backPath?: string }
> = {
  email: { path: '/signup/email/', step: 0 },
  phone: { path: '/signup/phone/', step: 1, backPath: '/signup/email/' },
  phoneConfirm: {
    path: '/signup/phone-confirm/',
    step: 2,
    backPath: '/signup/phone/',
  },
  apple: { path: '/signup/apple/', step: 0 },
  google: { path: '/signup/google/', step: 0 },
};
export const SIGNUP_FORM_PATHS = Object.values(SIGNUP_FORM_ROUTES).map(
  (route) => route.path
);
export const MAX_INPUT_LENGTH = 30;
export const MAX_EMAIL_INPUT_LENGTH = 254;
export const MIN_USERNAME_LENGTH = 3;
export const USERNAME_VALIDATION = /^[A-Za-z0-9_]+$/;
// Matches characters not in the specified set
export const DISALLOWED_USERNAME_CHARACTERS = /[^A-Za-z0-9_]/g;
export const MIN_PASSWORD_LENGTH = 8;
// Checks for no whitespace and inclusion of special characters and numbers
export const NUMBER_SPECIAL_CHARACTER_VALIDATION =
  /^(?=.*\d)(?=.*[^a-zA-Z0-9])(?!.*\s).+$/;
export const PASSWORD_NUMBER_VALIDATION = /^(?=.*\d)/;
// Checks for no whitespace and inclusion of special characters
export const PASSWORD_SPECIAL_CHARACTER_VALIDATION =
  /^(?!.*\s)(?=.*[^a-zA-Z0-9]).*$/;

export const SIGNUP_ERROR_TEXT: { [key: string]: string } = {
  SIGNUP_EMAIL_TAKEN: 'Signup.EmailTaken',
  USERNAME_INVALID: 'Username.Invalid',
  USERNAME_TAKEN: 'Username.Unavailable',
  DEVICE_BANNED: 'Signup.DeviceBanned',
  EMAIL_TAKEN: 'Signup.EmailTaken',
  PASSWORD_WEAK: 'Password.Strength',
  USERNAME_ILLEGAL: 'Username.Unavailable',
  PASSWORD_LEAKED: 'Password.Strength',
  VERIFICATION_CODE_EXPIRED: 'Signup.VerificationCodeExpired',
};

export const SIGNUP_VALIDATION_RULES: Partial<
  Record<
    keyof UserSignupDetails,
    Omit<
      // eslint-disable-next-line
      RegisterOptions<UserSignupDetails, any>,
      'valueAsNumber' | 'valueAsDate' | 'setValueAs' | 'disabled'
    >
  >
> = {
  firstName: {
    required: {
      value: true,
      message: 'FirstName.Required',
    },
    maxLength: {
      value: MAX_INPUT_LENGTH,
      message: 'MaximumLength',
    },
  },
  lastName: {
    required: {
      value: true,
      message: 'LastName.Required',
    },
    maxLength: {
      value: MAX_INPUT_LENGTH,
      message: 'MaximumLength',
    },
  },
  username: {
    required: {
      value: true,
      message: 'Username.Required',
    },
    minLength: {
      value: MIN_USERNAME_LENGTH,
      message: 'Username.Length',
    },
    maxLength: {
      value: MAX_INPUT_LENGTH,
      message: 'MaximumLength',
    },
    pattern: {
      value: USERNAME_VALIDATION,
      message: 'Username.Invalid',
    },
  },
  password: {
    required: true,
    minLength: MIN_PASSWORD_LENGTH,
    maxLength: MAX_INPUT_LENGTH,
    pattern: NUMBER_SPECIAL_CHARACTER_VALIDATION,
  },
  email: {
    required: {
      value: true,
      message: 'Email.Required',
    },
    maxLength: {
      value: MAX_EMAIL_INPUT_LENGTH,
      message: 'MaximumLength',
    },
  },
};

export const PHONE_CODES_LIST = Object.values(PHONE_CODES);
export const NEW_INPUT_STYLE_OPTIONS = PHONE_CODES_LIST.map((region) => ({
  label: region.name,
  value: region.code,
  activeLabel: region.prefix,
}));

export const VERIFY_PHONE_ERROR_TEXT: { [key: string]: string } = {
  PHONE_NUMBER_NOT_VALID: 'VerifyPhone.PhoneNotValid',
  BAD_REQUEST: 'VerifyPhone.PhoneIsRequired',
  PHONE_NUMBER_ALREADY_VERIFIED: 'VerifyPhone.PhoneAlreadyVerified',
  TOO_MANY_REQUESTS: 'VerifyPhone.TooManyRequests',
};

export const VERIFY_PHONE_CONFIRM_ERROR_TEXT: { [key: string]: string } = {
  CODE_MISMATCH: 'VerifyCode.DontMatch',
  BAD_REQUEST: 'VerifyCode.VerificationCodeRequired',
  PHONE_NUMBER_ALREADY_VERIFIED: 'VerifyPhone.PhoneAlreadyVerified',
  TOO_MANY_REQUESTS: 'VerifyPhone.TooManyRequests',
};

export enum MarketingSubscriptionStatuses {
  OPTED_IN = 'OPTED_IN',
  SUBSCRIBED = 'SUBSCRIBED',
  UNSUBSCRIBED = 'UNSUBSCRIBED',
}
