import { deleteCookie, getCookie } from 'cookies-next';

import { Platform } from '../activityTracker/constants';

import { blacklistedLoginPaths } from './constants';

import { MagicLinkRequest } from '@/modules/magicLinks/types.ts';
import { getPlatform } from '@/modules/activityTracker/helpers.ts';
import { isEmail } from '@/utils/isEmail.ts';
import {
  AccountLinkingCredential,
  CombinedLoginErrorResponse,
  MfaRequiredResponse,
} from '@/modules/login/types.ts';
import { CHECKOUT_LANDING_PATH } from '@/modules/checkout/constants';
import { getCheckoutProducts } from '@/modules/checkout/helpers';
import { CheckoutProduct } from '@/modules/checkout/types';
import { User } from '@/modules/user/types';
import { PERSISTENT_ID_KEY } from '@/constants/cookies';
import { updateUserSettings } from '@/modules/user/api';
import {
  COOKIE_ACCOUNT_LINKING_TOKEN_GOOGLE,
  COOKIE_ACCOUNT_LINKING_TOKEN_APPLE,
  COOKIE_ACCOUNT_LINKING_SSO_EMAIL,
} from '@/modules//auth/constants';

/**
 * The purpose of this helper is to make sure that we are not trying to redirect
 * to a blacklisted redirect path after login. For example, if we started on the
 * forgotten password page and clicked the login button in the nav, the initial redirect
 * arg would be the path for the forgotten password page. However, if we were to login to
 * forgotten password once logged in, we would hit an error so this helper is intended to
 * stop that from happening.
 */
export function getLoginRedirectParams(redirectPath: string) {
  const matches = blacklistedLoginPaths.filter((blacklistedLoginPath) =>
    redirectPath.match(blacklistedLoginPath)
  );
  return matches.length > 0
    ? ''
    : `?redirect=${encodeURIComponent(redirectPath)}`;
}

/**
 * Get parameters for a magic link request based on the provided app cookie.
 *
 * This function determines the platform and whether the app cookie represents
 * an email or username, then constructs the appropriate request object.
 *
 * @param {string} appCookie - The app cookie which is either an email or a username.
 * @returns {Promise<MagicLinkRequest>} A promise that resolves to the magic link request parameters.
 */
export async function getMagicLinkParams(
  appCookie: string,
  platform?: Platform
): Promise<MagicLinkRequest> {
  const requestingPlatform = platform || (await getPlatform());
  const persistentId = getCookie(PERSISTENT_ID_KEY);
  const baseParams = {
    requesting_platform: requestingPlatform,
    persistent_id: persistentId,
  };

  return isEmail(appCookie)
    ? {
        ...baseParams,
        email: appCookie,
      }
    : {
        ...baseParams,
        username: appCookie,
      };
}

/**
 * Checks if the login error is due to Multi-Factor Authentication (MFA) requirement.
 *
 * @param {number} status - The HTTP status code from the login response.
 * @param {CombinedLoginErrorResponse} loginErrorRespData - The login error response data.
 * @returns {boolean} - Returns `true` if the login error is due to MFA requirement, otherwise `false`.
 */
export function isMfaLoginError(
  status: number,
  loginErrorRespData: CombinedLoginErrorResponse
): boolean {
  return (
    status === 403 &&
    loginErrorRespData.error === 'MFA_REQUIRED' &&
    !!(loginErrorRespData as MfaRequiredResponse).mfa_token
  );
}

export function validateCheckoutRedirect(userId: number, redirect?: string) {
  if (CHECKOUT_LANDING_PATH && redirect?.startsWith(CHECKOUT_LANDING_PATH)) {
    const products = getCheckoutProducts();

    if (!products || !Array.isArray(products)) {
      return null;
    }
    const firstProduct = products[0] as CheckoutProduct;
    if (
      firstProduct.seller?.id &&
      userId &&
      firstProduct.seller?.id !== userId
    ) {
      return redirect;
    }
    if (firstProduct.slug) {
      return `/products/${firstProduct.slug}`;
    }
    return null;
  }
  return redirect;
}

export function validateRedirect(
  initialPath: string | null,
  user: Pick<User, 'id' | 'username'>
) {
  // Prevent redirecting to external URLs
  if (!initialPath || initialPath.startsWith('http')) {
    return `${window.location.origin}/`;
  }
  let redirect = validateCheckoutRedirect(user.id, initialPath) || '/';
  /* @johnf 21-02-23: when a user is redirected to a url which follows their
   * username, we pass a placeholder [username] and replace it with their
   * actual username here, e.g. when trying to access likes when logged out */
  redirect = redirect?.replace('/[username]/', `/${user.username}/`);

  const url = new URL(redirect, window.location.origin);

  return url.toString();
}

export enum MFALoginErrorType {
  ChallengeCountExceeded = 'MFA_CHALLENGE_COUNT_EXCEEDED',
  ChallengeNotFound = 'MFA_CHALLENGE_NOT_FOUND',
}

export function parseMFAChallengeError(
  errorMessages: {
    default: string;
    tooManyAttempts: string;
  },
  internalError?: {
    error?: MFALoginErrorType;
    error_message: string;
  }
) {
  const { error: errorType = '', error_message: internalErrorMessage = '' } =
    internalError || {};

  if (errorType === MFALoginErrorType.ChallengeCountExceeded) {
    /**
     * Slightly different to being rate limited, this is when the
     * user has created too many challenges without completing them.
     */
    return { message: errorMessages.tooManyAttempts || errorMessages.default };
  }

  return { message: internalErrorMessage || errorMessages.default };
}

export function parseMFALoginError(
  errorMessages: {
    default: string;
    incorrectCode: string;
    tooManyAttempts: string;
  },
  internalError?: {
    code: number;
    error_message?: string;
  }
) {
  let errorMessage;
  const { code: statusCode = '', error_message: internalErrorMessage = '' } =
    internalError || {};

  switch (statusCode) {
    case 401: {
      return {
        message: internalErrorMessage || errorMessages.incorrectCode,
        isNonRecoverable: false,
      };
    }
    case 400:
    case 409:
    case 429: {
      /**
       * Rate limited by twilio, the backend, or routing. A 409 indicates
       * a concurrent login attempt is happening, it is an unrecoverable
       * state.
       */
      errorMessage = internalErrorMessage || errorMessages.tooManyAttempts;
      break;
    }
    default: {
      errorMessage = internalErrorMessage;
      break;
    }
  }

  return {
    message: errorMessage || errorMessages.default,
    isNonRecoverable: true,
  };
}

export async function handleAccountLinking(
  accountLinkingCredential: AccountLinkingCredential | null,
  sendLinkAccountSuccessEvent: (
    accountLinkingCredential: AccountLinkingCredential
  ) => void
) {
  if (accountLinkingCredential) {
    try {
      await updateUserSettings(accountLinkingCredential);
      sendLinkAccountSuccessEvent(accountLinkingCredential);
    } catch {
      /* If there is an account linking error we can still log them in
                 and repeat the process next time they log in.
              */
      return;
    }
  }
}

export function deleteAccountLinkingCookies() {
  deleteCookie(COOKIE_ACCOUNT_LINKING_TOKEN_GOOGLE);
  deleteCookie(COOKIE_ACCOUNT_LINKING_TOKEN_APPLE);
  deleteCookie(COOKIE_ACCOUNT_LINKING_SSO_EMAIL);
}
