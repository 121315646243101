import { useState, useEffect } from 'react';

type Props = {
  fallback: string;
  appPath: string;
  stage: string;
  referer?: string;
  feature?: string;
  channel?: string;
  campaign?: string;
};

export function useBranchDeepLink({
  fallback,
  appPath,
  stage,
  referer = '',
  feature = 'organic',
  channel = 'website',
  campaign = '',
}: Props) {
  const BRANCH_TOKEN = process.env.NEXT_PUBLIC_BRANCH_TOKEN!;

  const [deepLink, setDeepLink] = useState(fallback);

  useEffect(() => {
    function createLink() {
      window.branch.link(
        {
          channel,
          stage,
          feature,
          campaign,
          data: {
            referring_user: referer,
            $deeplink_path: appPath,
            $desktop_url: fallback,
            $canonical_url: fallback,
          },
        },
        // eslint-disable-next-line consistent-return
        (_err, link) => {
          if (link) {
            return setDeepLink(link);
          }
        }
      );
    }

    if (!window.branch) {
      import('branch-sdk').then(() => {
        const options = {
          no_journeys: false,
        };
        window.branch.init(BRANCH_TOKEN, options);
        createLink();
      });
    } else {
      createLink();
    }
  }, [appPath, campaign, stage, feature, referer, channel, fallback]);

  return deepLink;
}
