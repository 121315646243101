import {
  Ga4Item,
  Ga4Purchase,
  GaEcommerceItemData,
  GaEcommerceTransactionData,
  RakutenItem,
} from '../checkout/types';

export function getGA4Item(
  item: GaEcommerceItemData,
  transaction: GaEcommerceTransactionData
): Ga4Item {
  return {
    affiliation: transaction.affiliation,
    item_id: item.sku,
    item_name: item.name,
    price: item.price,
    quantity: item.quantity,
  };
}

export function getGA4Purchase(
  items: GaEcommerceItemData[],
  transaction: GaEcommerceTransactionData
): Ga4Purchase {
  return {
    currency: transaction.currency,
    items: items.map((item) => getGA4Item(item, transaction)),
    shipping: transaction.shipping,
    transaction_id: transaction.id,
    value: transaction.value,
  };
}

export function getLineItems(items: GaEcommerceItemData[]): RakutenItem[] {
  const lineItems = items.map((item) => {
    return {
      quantity: 1,
      unitPrice: item.price,
      sku: item.sku,
      productName: item.name,
    };
  });

  return lineItems;
}
