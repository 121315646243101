import React, { forwardRef } from 'react';
import clsx from 'clsx';

import styles from './styles.module.css';

type Props = React.ButtonHTMLAttributes<HTMLButtonElement>;

export const ButtonMinimal = forwardRef<HTMLButtonElement, Props>(
  function ButtonMinimal({ className, ...rest }, ref) {
    return (
      <button
        {...rest}
        className={clsx(styles.buttonMinimal, className)}
        ref={ref}
      />
    );
  }
);
