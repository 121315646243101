import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import { isTouchDevice } from './isTouchDevice';
import { DeviceType } from './types';

import { RootState } from '@/modules/redux/stores/storeClient';

function userAgentToDevice(userAgent?: string): DeviceType {
  if (!userAgent) {
    return DeviceType.GENERAL;
  }

  if (userAgent.includes('iPhone') || userAgent.includes('iPad')) {
    return DeviceType.IOS;
  } else if (userAgent.includes(DeviceType.ANDROID)) {
    return DeviceType.ANDROID;
  }

  return DeviceType.GENERAL;
}

function isMobile(device: DeviceType) {
  return device === DeviceType.IOS || device === DeviceType.ANDROID;
}

export function useUserAgent(initialDevice?: DeviceType) {
  const deviceUserAgent = useSelector(
    (state: RootState) => state.device?.userAgent
  );
  const [device, setDevice] = useState<DeviceType>(
    initialDevice ?? userAgentToDevice(deviceUserAgent)
  );
  const [isMobileDevice, setMobileDevice] = useState<boolean>(isMobile(device));
  const [isMobileTouchDevice, setMobileTouchDevice] = useState<boolean>(false);

  useEffect(() => {
    const { userAgent } = window.navigator;
    if (userAgent) {
      setDevice(userAgentToDevice(userAgent));
    }
  }, []);

  useEffect(() => {
    setMobileDevice(isMobile(device));
    setMobileTouchDevice(isMobile(device) && isTouchDevice());
  }, [device]);

  return { device, isMobileDevice, isMobileTouchDevice };
}
