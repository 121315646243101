/**
 * Hook to call a selection of tracking events, including Segment, Google Analytics and Branch.
 *
 * Google Analytics is used for page views and ecommerce events.
 *
 * We send the Google Measurement ID in production and the Gtag ID in staging to ReactGA4.
 */

import { useCallback } from 'react';

import { SegmentEventType, SegmentEvents, SegmentIdentify } from './types';
import { getGA4Purchase, getLineItems } from './gaHelpers';

import {
  GaEcommerceItemData,
  GaEcommerceTransactionData,
} from '@/modules/checkout/types';

export function useExternalTracking() {
  const BRANCH_TOKEN = process.env.NEXT_PUBLIC_BRANCH_TOKEN!;

  const init = useCallback(() => {
    window.INITIALIZED_TRACKING = true;
  }, []);

  const shouldTrack = useCallback(
    () => window.navigator.doNotTrack !== '1',
    []
  );

  const sendGAEvent = useCallback(
    (event: unknown) => {
      if (!shouldTrack()) {
        return false;
      }
      if (!window.INITIALIZED_TRACKING) {
        init();
      }
      if (window.dataLayer) {
        return window.dataLayer.push(event);
      }
    },
    [shouldTrack, init]
  );

  /**
   *
   * @param items product items to be purchased
   * @param transaction transaction data after successful purchase
   * @returns sends the purchase and add to cart events to GA and GA4.
   */
  const sendEcommerceEvent = useCallback(
    (
      items: GaEcommerceItemData[],
      transaction: GaEcommerceTransactionData,
      tax?: string
    ) => {
      if (!shouldTrack()) {
        return false;
      }
      if (!window.INITIALIZED_TRACKING) {
        init();
      }

      const ga4Purchase = getGA4Purchase(items, transaction);
      if (window.dataLayer) {
        window.dataLayer.push({
          ecommerce: null,
        });
        return window.dataLayer.push({
          event: 'purchase',
          ecommerce: {
            currency: ga4Purchase.currency,
            items: ga4Purchase.items,
            shipping: ga4Purchase.shipping,
            transaction_id: ga4Purchase.transaction_id,
            value: ga4Purchase.value,
            tax: parseFloat(tax || '0.00'),
          },
          lineItems: getLineItems(items),
        });
      }
    },
    [shouldTrack, init]
  );

  function initBranch() {
    return import('branch-sdk').then(() => {
      const options = {
        no_journeys: false,
      };
      window.branch.init(BRANCH_TOKEN, options);
      return window.branch;
    });
  }

  async function getBranch() {
    if (typeof window.branch !== 'undefined') {
      return window.branch;
    }
    return await initBranch();
  }

  async function identifyBranchUser(userId: number) {
    if (!shouldTrack()) {
      return false;
    }

    const branch = await getBranch();
    if (branch) {
      return branch.setIdentity(userId.toString());
    }
    return false;
  }

  async function logoutBranch() {
    if (!shouldTrack()) {
      return false;
    }
    const branch = await getBranch();
    if (branch) {
      return branch.logout();
    }
    return false;
  }

  async function sendBranchEvent(
    eventName: string,
    data = {},
    contentItems: Record<string, unknown>[] = [{}]
  ) {
    if (!shouldTrack()) {
      return false;
    }
    const branch = await getBranch();
    if (branch) {
      return branch.logEvent(eventName, data, contentItems);
    }
    return false;
  }

  function sendSegmentEvent(eventName: SegmentEventType, data: SegmentEvents) {
    const { analytics } = window;
    if (
      !shouldTrack() ||
      process.env.SEGMENT_ENABLED === 'false' ||
      !analytics
    ) {
      return false;
    }

    if (!window.INITIALIZED_TRACKING) {
      init();
    }

    if (window.branch?.getBrowserFingerprintId) {
      return window.branch.getBrowserFingerprintId((_, fingerprint) => {
        analytics.track(eventName, {
          ...data,
          browser_fingerprint_id: fingerprint,
        });
      });
    }

    return analytics.track(eventName, data);
  }

  function sendSegmentIdentification(userId: string, data: SegmentIdentify) {
    const { analytics } = window;

    if (
      !shouldTrack() ||
      process.env.SEGMENT_ENABLED === 'false' ||
      !analytics
    ) {
      return false;
    }
    if (!window.INITIALIZED_TRACKING) {
      init();
    }

    return analytics.identify(userId, data);
  }

  function sendBingEvent(type: string, data: Record<string, unknown>) {
    if (!shouldTrack() || !window?.uetq?.push) {
      return;
    }

    if (!window.INITIALIZED_TRACKING) {
      init();
    }

    window.uetq.push('event', type, data);
  }

  return {
    ga: {
      sendEvent: sendGAEvent,
      sendEcommerceEvent,
    },
    branch: {
      sendEvent: sendBranchEvent,
      identifyUser: identifyBranchUser,
      logout: logoutBranch,
    },
    segment: {
      sendEvent: sendSegmentEvent,
      identifyUser: sendSegmentIdentification,
    },
    bing: {
      sendEvent: sendBingEvent,
    },
  };
}
