type NonEmptyString<T extends string> = T extends '' | ' ' ? never : T;

export function appendParams<
  U extends string,
  K extends string,
  V extends string,
>(
  originalUrl: NonEmptyString<U>,
  ...params: [NonEmptyString<K>, NonEmptyString<V>][]
): string {
  try {
    const [basePath, queryParamsString] = originalUrl.split('?');
    const queryParams = new URLSearchParams(queryParamsString);

    params.forEach(([key, val]) => queryParams.set(key, val));

    return `${basePath}?${queryParams.toString()}`;
  } catch {
    return originalUrl;
  }
}
