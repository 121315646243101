'use client';
/* eslint-disable no-restricted-imports */
import React, { forwardRef, MouseEvent, useContext } from 'react';
import Link from 'next/link';
import clsx from 'clsx';
import { useSearchParams } from 'next/navigation';

import styles from './styles.module.css';

import { isRouteMigrated } from '@/modules/routing/getRoutesManifest';
import { ROUTE_CHANGE_START_EVENT } from '@/modules/routing/constants';
import { isServerCheck } from '@/modules/env/isServerCheck';
import { useCurrentUser } from '@/modules/user/useCurrentUser';
import { LocationContext } from '@/modules/location/LocationContext';
import {
  ensureTrailingSlash,
  isProtectedRoute,
  replaceUsernameInPath,
} from '@/modules/routing/helpers';
import { moduleOriginParamName } from '@/modules/activityTracker/constants';
import { appendParams } from '@/modules/routing/appendParams/appendParams.ts';

type Props = React.ComponentProps<typeof Link>;

export const ExtendedLink = forwardRef(function ExtendedLink(
  props: Props,
  ref
) {
  const { href, as, passHref, onClick, className } = props;
  const [isAuthenticated] = useCurrentUser();
  const { userLocation, localisedRoutes, defaultLocation } =
    useContext(LocationContext);

  const linkProps = {
    ...props,
    className: clsx(styles.unstyledLink, className),
  };

  let modifiedHref = '';
  const originalHref = typeof href === 'string' ? href : href.href || '#';

  if (typeof as === 'string') {
    modifiedHref = as;
    const queryParamsIndex = originalHref?.indexOf('?');
    if (queryParamsIndex > -1) {
      modifiedHref += originalHref.substring(queryParamsIndex);
    }
  } else {
    modifiedHref = originalHref;
  }

  const [_, ...pathSegments] = modifiedHref.split('/');
  const [rootPath] = pathSegments;

  const isLocalisedRoute =
    userLocation !== defaultLocation &&
    localisedRoutes.some(
      (route) =>
        route === rootPath || (route === 'home' && modifiedHref === '/')
    );

  // always carry though the module origin if it exists
  const moduleOrigin = useSearchParams().get(moduleOriginParamName);
  if (moduleOrigin) {
    modifiedHref = appendParams(modifiedHref, [
      moduleOriginParamName,
      moduleOrigin,
    ]);
  }

  modifiedHref = replaceUsernameInPath(modifiedHref);

  function modifiedOnClick(e: MouseEvent<HTMLAnchorElement>) {
    document.dispatchEvent(new CustomEvent(ROUTE_CHANGE_START_EVENT));
    onClick?.(e);
  }

  /**
   * If the route is *NOT* migrated, use an <a> tag to direct to the old application
   *
   * A test ID is needed here as differentiating between Link and <a> in tests is difficult
   * as they both just render a plain <a> tag.
   */
  if (!isRouteMigrated(modifiedHref)) {
    const isProtected = isProtectedRoute(originalHref);

    if (!isAuthenticated && isProtected) {
      modifiedHref = `/login?redirect=${encodeURIComponent(modifiedHref)}`;
    }

    /** If this is a localised link, manually add the locale to the href */
    if (isLocalisedRoute) {
      modifiedHref = `/${userLocation}${modifiedHref}`;
    }

    /** If passHref is true, we need to return the given child and attach the href to it */
    if (passHref) {
      const child = React.Children.only(linkProps.children);

      return React.cloneElement(
        child as React.ReactElement<
          HTMLAnchorElement & { ref: unknown; 'data-testid': string }
        >,
        {
          ref,
          href: ensureTrailingSlash(modifiedHref),
          'data-testid': 'extendedLinkAnchor',
        }
      );
    }

    const { prefetch: _, ...linkPropsWithoutPrefetch } = linkProps;

    return (
      <a
        ref={ref as React.Ref<HTMLAnchorElement>}
        data-testid="extendedLinkAnchor"
        {...linkPropsWithoutPrefetch}
        href={ensureTrailingSlash(modifiedHref)}
        {...(!isServerCheck() ? { onClick: modifiedOnClick } : {})}
      >
        {linkProps.children}
      </a>
    );
  }

  const modifiedLinkProps = {
    ...linkProps,
    href: isLocalisedRoute ? `/${userLocation}${modifiedHref}` : modifiedHref,
  };

  return (
    <Link
      ref={ref as React.Ref<HTMLAnchorElement>}
      prefetch={false}
      {...modifiedLinkProps}
      {...(!isServerCheck() ? { onClick: modifiedOnClick } : {})}
      href={ensureTrailingSlash(String(modifiedLinkProps.href))}
      as={
        modifiedLinkProps?.as
          ? ensureTrailingSlash(String(modifiedLinkProps.as))
          : undefined
      }
    />
  );
});
