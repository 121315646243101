import { CurrencyCode } from '@/modules/currencies/constants';

export enum SegmentEventType {
  ORDER_COMPLETED = 'Order Completed',
  PRODUCT_ADDED = 'Product Added',
  PRODUCT_VIEWED = 'Product Viewed',
  SIGNED_IN = 'Signed In',
  SIGNED_OUT = 'Signed Out',
  SIGNED_UP = 'Signed Up',
  PRODUCTS_SEARCHED = 'Products Searched',
  PRODUCT_LIST_VIEWED = 'Product List Viewed',
}

type SegmentProductBase = {
  product_id: string;
  sku: string;
  category: string;
  name: string;
  brand?: string;
  price: string | number;
  currency?: CurrencyCode;
  url: string;
  quantity?: number;
  google_business_vertical?: string;
  email?: string;
};

export type SegmentProductViewed = SegmentProductBase;

export type SegmentProductAdded = SegmentProductBase;

type SegmentOrderProduct = Omit<SegmentProductBase, 'currency'>;

export type SegmentOrderCompleted = {
  order_id: string;
  affiliation: string;
  total: number;
  subtotal: number;
  revenue: number;
  shipping: number;
  tax?: number;
  discount: number;
  currency: string;
  skus: string[];
  products: SegmentOrderProduct[];
  google_business_vertical: string;
  email?: string;
};

// JR - 26/09/23 - temporarily not using the Product List Viewed event due to a reduction of monthly api calls being required.
// We are looking to reinstant this event in the future so I have left the structure in this file. The removal of sending this event
// to segment is within this PR: https://github.com/depop/web/pull/4556/files

type SegmentProductListViewed = {
  list_id: string;
  category: string;
  products: SegmentProductBase[];
  google_business_vertical: string;
};

type SegmentSignedOutOrIn = {
  userId: string;
  username: string;
};

type SegmentSignedUp = {
  userId: string;
  username: string;
  email: string;
  method: string;
};

type SegmentProductsSearched = {
  query: string;
  product_ids: string[];
  skus: string[];
  google_business_vertical: string;
};

export type SegmentEvents =
  | SegmentProductAdded
  | SegmentProductViewed
  | SegmentOrderCompleted
  | SegmentSignedOutOrIn
  | SegmentProductsSearched
  | SegmentSignedUp
  | SegmentProductListViewed;

export type SegmentIdentify = SegmentSignedOutOrIn;

export const segmentEventTypes = SegmentEventType;
